import React, { Component } from 'react';

export default function ChapterThumbnail(props) {
        return (
            <a href={ props.link }>
                <h2 className="white-text"> { props.title }</h2>
                <div className="thumbnail-container">
                    <img className="img-thumbnail" src={ props.imageSrc } />
                    <div className="chapter-overlay">Click to Read</div>
                </div>
            </a>
        )
}