import React, { Component } from 'react';
import { Route, Routes } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { Comic } from './components/Comic';
import { RedirectUser } from './components/RedirectUser';
import './RedSky.css'
import { FetchData } from './components/FetchData';
import ReactGA from "react-ga4";
ReactGA.initialize("G-QG3TLKS286");

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <Layout>
                <Routes>
                    <Route exact path='/' element={<Home/>} />
                    <Route path='/comic' element={<Comic />} />
                    <Route exact path='/fetch-data' element={<FetchData />} />
                    <Route exact path='/redirect' element={<RedirectUser />} />
                </Routes>
            </Layout>
        );
    }
}
