import React, { Component } from 'react';
import ChapterThumbnail from './ChapterThumbnail';

export class Home extends Component {
  static displayName = Home.name;

  render () {
    return (
    <div>
        <div className="row">
            <div className="col-md-4">
                    <ChapterThumbnail link="/comic?pageNum=0&chapterNum=1" title="Chapter 1" imageSrc="/Images/Chapter_1/cover.png" ></ChapterThumbnail>
            </div>
            <div className="col-md-4">
                    <ChapterThumbnail link="/comic?pageNum=0&chapterNum=2" title="Chapter 2" imageSrc="/Images/Chapter_2/Comic2_001.webp" ></ChapterThumbnail>
            </div>
            <div className="col-md-4">
                    <ChapterThumbnail link="/comic?pageNum=0&chapterNum=3" title="Chapter 3" imageSrc="/Images/Chapter_3/Comic_001.webp" ></ChapterThumbnail>
            </div>
        </div >
        <div className="row">
            <div className="col-md-4">
                    <ChapterThumbnail link="/comic?pageNum=0&chapterNum=4" title="Chapter 4" imageSrc="/Images/Chapter_4/Chapter%204_001_result.webp" ></ChapterThumbnail>
            </div>
                <div className="col-md-4">
                    <ChapterThumbnail link="/comic?pageNum=0&chapterNum=5" title="Chapter 5" imageSrc="/Images/Chapter_5/Chapter5_001.webp" ></ChapterThumbnail>
            </div>
        </div>
    </div >
    );
  }
}
