import React, {  useState } from 'react';
import ReactGA from "react-ga4";
import { Navigate } from 'react-router-dom';

export function RedirectUser() {
    const params = new URLSearchParams(window.location.search);
    const source = params.get("source");

    ReactGA.event({
        category: "redirect",
        action: source
    });

    return <Navigate to='/' />
}