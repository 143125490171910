import React, { Component, useState, useEffect, useRef } from 'react';
import $ from 'jquery';

export default function DynamicPage(props) {

    const [currentPanelIndex, setCurrentPanelIndex] = useState(0);
    const [prevPanelIndex, setPrevPanelIndex] = useState(0);
    const canvasRef = useRef(); //to do: actually use ref.
    const [goToLastPanel, setGoToLastPanel] = useState(false);
    const [goToFirstPanel, setGoToFirstPanel] = useState(false);
    const [canvasWidth, setCanvasWidth] = useState(1);
    const [canvasHeight, setCanvasHeight] = useState(1);
    const [touchStart, setTouchStart] = useState(null)
    const [touchEnd, setTouchEnd] = useState(null)
    const [transitionStart, setTransitionStart] = useState(null);

    let transitionLength = 500; //this is in milliseconds.
    let frameRate = 24;

    useEffect(() => {
        var canvas = document.getElementById('dynamicCanvas');

        let context = canvasRef.current.getContext('2d')
        context.filter = 'none';

        setCanvasSize(canvas);

        if (props.show) {
            if (goToLastPanel && props.panelData != null) {
                setCurrentPanelIndex(props.panelData.length - 1);
                setGoToLastPanel(false);
                return;
            }

            if (goToFirstPanel && props.panelData != null) {
                setCurrentPanelIndex(0);
                setGoToFirstPanel(false);
                return;
            }

            draw();

            if (transitionStart != null) {
                //then redraw until the transition ends.
                let frameCount = 0;
                let frameTime = Math.floor(1000 / frameRate);

                let interval = setInterval(function () {
                    if (frameCount > transitionLength) {
                        draw(); //one last draw to be sure the transition ends at the correct size.
                        clearInterval(interval);
                    }
                    else {
                        draw();
                        frameCount += frameTime;
                    }
                }, frameTime);
            }
        }
        //no constructor for functional components so I must resort to this nonsense. Disgusting.
        window.addEventListener('keydown', handleKeys);
        window.addEventListener('resize', handleResize);
        window.addEventListener("orientationchange", handleResize);

        return () => {
            window.removeEventListener('keydown', handleKeys);
            window.removeEventListener('resize', handleResize);
            window.removeEventListener("orientationchange", handleResize);

        };
    }, [currentPanelIndex, props.show, props.image, props.panelData])

    function setCanvasSize() {
        try {
            //to do: get rid of these document.getElementsByClassName calls. Use state management to do better.
            const navbarHeight = document.getElementsByClassName("navbar")[0].getBoundingClientRect().height;
            const footerHeight = document.getElementsByClassName("redskyfooter")[0].getBoundingClientRect().height;
            const height = window.innerHeight - navbarHeight - footerHeight - 30; //to do: replace magic number.
            const width = document.body.clientWidth;

            var canvas = document.getElementById('dynamicCanvas');

            let context = canvas.getContext('2d');
            context.imageSmoothingEnabled = true;

            var style = canvas.style;
            style.width = (width) + 'px';
            style.height = (height) + 'px';
            canvas.width = canvasWidth;
            canvas.height = canvasHeight;

            setCanvasWidth(width * 4);
            setCanvasHeight(height * 4);
        }
        catch {
            console.log("error setting canvas size.")
        }
    }

    function draw() {
        let prevWeight = 0;
        let currentWeight = 1;
        let time = Date.now();
        let timeSinceTransitionStarted = time - transitionStart;

        if (transitionStart != null) {
            if (timeSinceTransitionStarted > transitionLength) {
                setTransitionStart(null);
            }
            else {
                currentWeight = timeSinceTransitionStarted / transitionLength;
                prevWeight = 1 - currentWeight;
            }
        }

        var canvas = document.getElementById('dynamicCanvas');
        setCanvasSize(canvas);

        var sx, sy, sw, sh, dx, dy, dw, dh;

        if (props.panelData == null || props.panelData.length == 0 || props.panelData[currentPanelIndex] == null) {
            sx = 0;
            sy = 0;
            sw = props.image.width;
            sh = props.image.height;
        }
        else {
            let currentPanel = props.panelData[currentPanelIndex];
            let prevPanel = props.panelData[prevPanelIndex];
            sx = currentPanel[0][0] * currentWeight + prevPanel[0][0] * prevWeight;
            sy = currentPanel[0][1] * currentWeight + prevPanel[0][1] * prevWeight;
            sw = (currentPanel[1][0] * currentWeight + prevPanel[1][0] * prevWeight) - sx;
            sh = (currentPanel[1][1] * currentWeight + prevPanel[1][1] * prevWeight) - sy;
        }

        var sourceAspectRatio = sw / sh;

        var destinationAspectRatio = canvasWidth / canvasHeight;
        var scale = Math.min(canvasWidth / sw, canvasHeight / sh);

        dw = sw * scale;
        dh = sh * scale;

        dx = (canvas.width - dw) / 2
        dy = (canvas.height - dh) / 2

        let context = canvasRef.current.getContext('2d')
        //context.clearRect(0, 0, props.image.width, props.image.height);
        context.fillStyle = '#e03c2e';
        context.fillRect(0, 0, canvas.width, canvas.height);

        context.drawImage(props.image,
            sx, sy,
            sw, sh,
            dx, dy,
            dw, dh);
    }

    function handleKeys(e) {
        if (e.keyCode == '37') {
            // left arrow
            goPrev();
        }
        else if (e.keyCode == '39') {
            // right arrow
            goNext();
        }
    }

    function handleResize(e) {
        let canvas = document.getElementById('dynamicCanvas');
        setCanvasSize(canvas);
        draw();
      }

    const onTouchStart = (e) => {
        setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
        setTouchStart(e.targetTouches[0].clientX)
    }

    const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX)

    const onTouchEnd = () => {

        if (!touchStart || !touchEnd) return;
        var canvas = document.getElementById('dynamicCanvas');
        const rect = canvas.getBoundingClientRect();
        const minSwipeDistance = rect.width / 4;

        const distance = touchStart - touchEnd
        const isLeftSwipe = distance >= minSwipeDistance
        const isRightSwipe = distance <= -minSwipeDistance
        if (isLeftSwipe) {
            goNext();
        }
        if (isRightSwipe) {
            goPrev();
        }
    }

    function goPrev() {
        if (currentPanelIndex > 0) {
            setPrevPanelIndex(currentPanelIndex);
            setCurrentPanelIndex(currentPanelIndex - 1);
            setTransitionStart(Date.now());
        }
        else {
            props.prevFunc();
            setGoToLastPanel(true);
            setPrevPanelIndex(0);
            setTransitionStart(null);
        }
    }

    function goNext() {
        if (currentPanelIndex < props.panelData.length - 1) {
            setPrevPanelIndex(currentPanelIndex);
            setCurrentPanelIndex(currentPanelIndex + 1);
            setTransitionStart(Date.now());
        }
        else {
            props.nextFunc();
            setGoToFirstPanel(true);
            setCurrentPanelIndex(0);
            setPrevPanelIndex(0);
            setTransitionStart(null);
        }
    }

    return (
        <canvas onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd} ref={(canvasRef)} id="dynamicCanvas" className="dynamic-canvas" onKeyDown={handleKeys} />
    );
}