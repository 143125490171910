import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import DynamicPage from './DynamicPage';
import { Container } from 'reactstrap';
import ReactGA from "react-ga4";

function NavigationButtons(props) {
    function isPrevButtonDisabled() {
        return !props.loaded || props.isAnimating || (props.page == 0 && props.chapter == 1)
    }

    function isNextButtonDisabled() {
        return !props.loaded || props.isAnimating || (props.page == props.pageCount - 1 && props.chapter == props.lastChapter)
    }


    return (
        <div>
            <button className={isPrevButtonDisabled() ? "prevLink disabledLink" : "prevLink"} onClick={props.onPrevClick} disabled={isPrevButtonDisabled()}>
                {props.prevText}
            </button>
            <button className={isNextButtonDisabled() ? "nextLink disabledLink" : "nextLink"} onClick={props.onNextClick} disabled={isNextButtonDisabled()}>
                {props.nextText}
            </button>
        </div>
    );
}

export function Comic(props) {
    const params = new URLSearchParams(window.location.search);

    const [page, setPage] = useState(parseFloat(params.get("pageNum") || 0));
    const [chapter, setChapter] = useState(parseFloat(params.get("chapterNum") || 1));
    const [prevText, setPrevText] = useState('Previous');
    const [nextText, setNextText] = useState('Next');

    const [useDynamicView, setUseDynamicView] = useState(null); //to do: set based on screen dimensions.
    const [initialLoaded, setInitialLoaded] = useState(false);
    const [loaded, setLoaded] = useState(false);

    const [currentImage, setCurrentImage] = useState(new Image());
    const [nextImage, setNextImage] = useState(new Image());
    const [loadedImage, setLoadedImage] = useState(new Image());

    const [pageCount, setPageCount] = useState(null);
    const [panelData, setPanelData] = useState([]);
    const [isFadingOut, setIsFadingOut] = useState(false);
    //to do: remove magic number 5 and get this from elsewhere...
    const lastChapter = 5;

    useEffect(() => {
        if (useDynamicView == null)
        if (window.innerHeight > window.innerWidth) {
            setUseDynamicView(true);
        }
        else {
            setUseDynamicView(false);
        }

        GetPage(chapter, page).then((result) => {

            const image = new Image();
            image.src = result.imageUrl;
            setPageCount(result.pageCount) //to do: you should get this metadata in a seperate call from Comic()

            setButtonText();

            image.onload = function () {
                setLoadedImage(image);
                setLoaded(true);
                //preload next image
                if (page < pageCount - 1) {
                    GetPage(chapter, page + 1).then((preloadedResult) => {
                        const img = new Image();
                        img.src = preloadedResult.imageUrl;
                        setNextImage(img);
                    })
                }
            }
            setPanelData(JSON.parse(result.panelData)); //to do: you should get this metadata in a seperate call from Comic()
        });
    }, [chapter, page, useDynamicView]);

    useEffect(() => {
        if ((!isFadingOut || useDynamicView) && loaded) {
            $('#displayedPage').removeClass("invisible");
            $('#displayedPage').removeClass("fade-out");
            $('#displayedPage').addClass("fade-in");
            setCurrentImage(loadedImage);
        }
    }, [isFadingOut, loaded]);


    async function GetPage(chapterNum, pageNum) {
        return await fetch(`page/get?PageNum=${pageNum}&ChapterNum=${chapterNum}`).then(result => result.json());
    }

    function setButtonText() {
        if (page == 0 && chapter != 1) {
            setPrevText("Previous Chapter");
        }
        else {
            setPrevText('Previous');
        }

        if (page == pageCount - 1 && chapter != lastChapter) {
            setNextText("Next Chapter");
        }
        else {
            setNextText('Next');
        }
    }

    function prevFunc() {
        if (chapter == 1 && page == 0) {
            return;
        }

        //to do: replace with fadeIn/fadeout function from an imported file.
        $('#displayedPage').removeClass("fade-in");
        $('#displayedPage').addClass("fade-out");
        setIsFadingOut(true);
        setLoaded(false);
        //scroll to top of page.
        window.scrollTo(0, 0);
        if (page != 0) {
            setPage(page - 1);
            swapPage(chapter, page - 1);
        }
        else if (chapter != 1) {
            const newChapterNum = chapter - 1;
            const newPageNum = 0;
            setPage(newPageNum);
            setChapter(newChapterNum);
            swapPage(newChapterNum, newPageNum);
        }
    }

    function nextFunc() {
        if (chapter == lastChapter && page == pageCount - 1) {
            return;
        }

        $('#displayedPage').removeClass("fade-in");
        $('#displayedPage').addClass("fade-out");
        //scroll to top of page.
        window.scrollTo(0, 0);
        setIsFadingOut(true);
        setLoaded(false);
        if (page != pageCount - 1) {
            setPage(page + 1);
            swapPage(chapter, page + 1);
        }
        else if (chapter != lastChapter) {
            const newChapterNum = chapter + 1;
            const newPageNum = 0;
            setPage(newPageNum);
            setChapter(newChapterNum)
            swapPage(newChapterNum, newPageNum);
        }
    }

    function swapPage(chapter, page) {
        window.history.replaceState(null, "Chapter " + chapter + " Page " + page, '/comic?pageNum=' + page + '&chapterNum=' + chapter)
        ReactGA.event({
            category: "comic",
            action: "view",
            label: "chapter" + chapter, // optional
            value: page, // optional, must be a number
        });
    }

    function animationEnd() {
        if (isFadingOut) {
            $('#displayedPage').addClass("invisible");
            setIsFadingOut(false);
        }
    }

    //to do: move to own component/file.
    function renderTable(chapter, columnCount = 10) {
        let rows = [];
        for (var i = 0; i < pageCount; i += columnCount) {
            let cell = []
            for (var j = 0; j < columnCount && i + j < pageCount; j++) {
                if (i + j == props.page) {
                    cell.push(<td key={"cell" + (i + j)} className="page-navigation-cell">
                        <a key={"hyperlink" + (i + j)} className="page-navigation-link disabledLink">
                            {i + j}
                        </a>
                    </td>)
                }
                else {
                    cell.push(<td key={"cell" + (i + j)} className="page-navigation-cell">
                        <a key={"hyperlink" + (i + j)} href={"/comic?pageNum=" + (i + j) + "&chapterNum=" + chapter} className="page-navigation-link">
                            {i + j}
                        </a>
                    </td>)
                }
            }
            rows.push(<tr key={i + j}>{cell}</tr>)
        }
        return (
            <div className="page-container">
                <div className="red-section">
                    <p>Chapter Navigation</p>
                    <table>
                        <tbody>
                            {rows}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }

    //Couldn't get react key events to work. Just go with old reliable.
    document.onkeydown = checkKey;

    function checkKey(e) {
        e = e || window.event;
        if (!useDynamicView) {
            if (!isFadingOut && loaded) {
                if (e.keyCode == '37') {
                    // left arrow
                    prevFunc();
                } else if (e.keyCode == '39') {
                    // right arrow
                    nextFunc();
                }
            }
        }
    }

    function getStyle() {
        return "visibility:hidden";
    }

    function initalLoadComplete() {
        setLoaded(true);
        setInitialLoaded(true);
    }

    if (useDynamicView) {
        return (
            <div>
                <div className="vertical-center">To move between panels, either swipe or use the arrow keys on PC.</div>
                <DynamicPage
                    image={currentImage}
                    nextImage={nextImage}
                    show={loaded}
                    panelData={panelData}
                    prevFunc={prevFunc.bind(this)}
                    nextFunc={nextFunc.bind(this)}
                    isFirstPage={page == 0}
                    isLastPage={page == pageCount - 1}
                />
                <div className="redskyfooter">
                    <button className="dynamic-view-btn" onClick={function () { setUseDynamicView(!useDynamicView); setIsFadingOut(false); }}>
                        Switch to Page View
                    </button>
                    <div>Chapter {chapter} Page {page}</div>
                </div>

            </div>
        );
    }
    else {
        return (
            <Container className={initialLoaded ? "" : "hidden"}>
                Chapter {chapter} Page {page}
                <NavigationButtons
                    page={page}
                    chapter={chapter}
                    prevText={prevText}
                    nextText={nextText}
                    onPrevClick={prevFunc}
                    onNextClick={nextFunc}
                    pageCount={pageCount}
                    loaded={loaded}
                    isAnimating={isFadingOut}
                    lastChapter={lastChapter}
                    tabIndex="1"
                />
                <img id="displayedPage" onLoad={initalLoadComplete} onAnimationEnd={animationEnd} className="pageImage" src={currentImage.src} />
                <NavigationButtons
                    page={page}
                    chapter={chapter}
                    prevText={prevText}
                    nextText={nextText}
                    onPrevClick={prevFunc}
                    onNextClick={nextFunc}
                    pageCount={pageCount}
                    loaded={loaded}
                    isAnimating={isFadingOut}
                    lastChapter={lastChapter}
                    tabIndex="1"
                />
                <button className="dynamic-view-btn" onClick={function () { setUseDynamicView(!useDynamicView) }}>
                    Switch to Panel View
                </button>
                {renderTable(chapter)}
            </Container>
        );
    }
}